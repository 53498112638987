import React from "react"
import NewHeader from "../components/docheader"
import banner from "../assets/images/banner.png"
import "../assets/styles/page/docArticlePage.scss"

const DocArticlePage = () => {
  return (
    <div className="article--content--container">
      <NewHeader />
      <div className="article--content--inner">
        <article className="main--article--content">
          <div className="article--banner--wrapper">
            <div className="banner--image--wrapper">
              <img src={banner} alt="" />
            </div>
            <div className="banner--overlap--wrapper">
              <div className="banner--overlap--text--inner">
                <h2 className="banner--overlap--headtext">Learning Lessons </h2>
                <p className="banner--overlap--subtext"> How to build a design education program for creative work.</p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  )
}

export default DocArticlePage
